<template>
    <div>
        <b-tabs
        vertical
        v-model="state.tabIndex"
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        pills
        nav-wrapper-class="col-md-3 col-12"
        nav-class="nav-left"
        >
        <b-tab>
            <template #title>
            <feather-icon icon="UserIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">General</span>
          </template>
          <general
          :handleSave="handleSave"
          :handleImageInput="handleImageInput"
          :invoiceSettings="state.invoiceSettings"
          />
        </b-tab>
        <b-tab>
            <template #title>
            <feather-icon icon="UserIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">Invoice Settings</span>
          </template>
          <invoice-setting 
          :handleSave="handleSave"
          :handleImageInput="handleImageInput"
          :invoiceSettings="state.invoiceSettings"/>
        </b-tab>
        <b-tab>
            <template #title>
            <feather-icon icon="UserIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">Shipping Method</span>
          </template>
          <shipping-method
          :handleSave="saveStoreOptions"
          :selectedMethod="state.selectedShippingMethod" 
          :selectionChanged="setShippingMethod"
          :shippingMethods="SHIPPING_METHOD"/>

        </b-tab>
        <b-tab>
            <template #title>
            <feather-icon icon="UserIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">Shipping Origin</span>
          </template>
          <shipping-origin 
          :handleSave="saveStoreOptions"
          :shippingOrigin="state.shippingOrigin.shippingOrigin"/>

        </b-tab>
        <b-tab>
            <template #title>
            <feather-icon icon="UserIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">Payment</span>
          </template>
          <payment 
          :selectedMethod="state.selectedPaymentMethod"
          :handleSave="saveStoreOptions"
          :selectionChanged="setPaymentMethod"
          :paymentMethods="PAYMENT_TYPES"/>
        </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import {BTabs,BTab} from 'bootstrap-vue';
import useStoreView from './useStoreView';
import General from './components/General.vue';
import ShippingMethod from './components/ShippingMethod.vue';
import Payment from './components/Payment.vue';
import InvoiceSetting from './components/InvoiceSetting.vue';
import ShippingOrigin from './components/ShippingOrigin.vue';

export default {
    components:{
        BTabs,
        Payment,
        ShippingOrigin,
        InvoiceSetting,
        ShippingMethod,
        General,
        BTab
    },
    updated(){
        console.log(this.state);
    },
    setup(){
        const {state,handleImageInput,handleSave,saveStoreOptions,PAYMENT_TYPES,setPaymentMethod,SHIPPING_METHOD,setShippingMethod} = useStoreView()
        console.log(state.selectedPaymentMethod);
        return {
            state,
            handleImageInput,
            setShippingMethod,
            setPaymentMethod,
            SHIPPING_METHOD,
            saveStoreOptions,
            PAYMENT_TYPES,
            handleSave
        }
    }
}
</script>