<template>
  <b-row>
    <b-col cols="12">
      <b-form-group>
        <v-select
          v-if="option != null"
          v-model="selected"
          chips
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          :options="options"
          class="select-size-lg"
          filled
          style="flex: 1"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>
  
  <script>
import { BRow, BCol, BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "vue-select/dist/vue-select.css";

// import useUsersList from '../courseCategoryStoreModule';
import axios from "@axios";
// import api, { getCall } from "../Service";
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  directives: {
    Ripple,
  },

  props: {
    initialCategories: [],
    categories: [],
    options:[],
    selected: null,
  },
  updated() {
    // console.log('sele', this.selected);
    // let temp = this.selected
    // const res = temp.map(i=>i.id)
    // console.log('ressssTTT',res);
  },
  methods: {
    updateSelections(a) {
      console.log(a);
    },

    // },
  },
  watch: {
    selected: function (newVal, coldVal) {
      console.log(this.initialCategories);
      console.log(this.selected);
      console.log("added", newVal);
      // const temp = newVal.map((i) => i.id);

      this.$emit("selectionChanged", newVal);
    },
  },

  data() {
    return {
      categories: null,
      option: [
        { name: "Square", value: "square", adddress: "259 Tariq Block" },
        //   { title: 'Rectangle' },
        //   { title: 'Rombo' },
        //   { title: 'Romboid' },
      ],
    };
  },
};
</script>

  <style>
.vs__dropdown-toggle {
  height: 40px;
  border-radius: 6px;
  border-color: #dddddd;
}
</style>
  