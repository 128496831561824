<template>
    <b-card>
    
        <div >
            <h4>Payment Method</h4>
        </div>
        <p>Update payment method</p>
        <b-row>
            <b-col cols="4">
            <selector :selected="selectedMethod" @selectionChanged="selectionChanged" :options="paymentMethods" style="min-width:15rem" />
        </b-col>
        </b-row>
        <b-row class="mt-1">
        <b-col cols="4">
        <b-button @click="handleSave('paymentMethod')" variant="primary">Save Changes</b-button>
        </b-col>
        </b-row>
    </b-card>
</template>

<script >

import {BCard,BRow,BCol,BButton} from 'bootstrap-vue'
import selector from '../../components/CustomerSelect.vue';

export default {
    components:{
    BCard,
    BRow,BCol,BButton,
    selector
    },
    props:{
        selectedMethod:{
            required:true
        },
        handleSave:{
            required:true
        },
        selectionChanged:{
            required:true
        },
        paymentMethods:{
            required:true
        }
    },
    updated(){
        console.log('sss',this.selectedMethod);

    },
    setup() {
        console.log('sss',this.paymentMethod);
    },
}
</script>
